input {
    &[type='datetime-local'],
    &[type='date'] {
        appearance: none;
        background-color: transparent;
    }
}

@media (prefers-color-scheme: dark) {
    input {
        &[type='datetime-local'],
        &[type='date'] {
            background-color: var(--fish-dark);
        }
    }

    input {
        &[type='datetime-local'],
        &[type='date'] {
            &::-webkit-calendar-picker-indicator {
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(152deg)
                    brightness(103%) contrast(103%);
                padding-right: 6px;
            }
        }
    }
}
