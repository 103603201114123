.btn,
form button,
button.btn,
input[type='file']::-webkit-file-upload-button {
    background-color: #f2f2f2;
    border: none;
    border-radius: var(--fish-radius);
    font-size: var(--fish-font-size);
    padding: 10px 16px;
    transition: 0.5s background-color;
    color: black;

    &:hover {
        background-color: #ddd;
    }

    @media (prefers-color-scheme: dark) {
        background-color: var(--fish-dark);
        color: var(--fish-white);

        &:hover {
            background-color: var(--fish-grey);
        }
    }
}
