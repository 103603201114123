ul,
ol {
    margin: 1em 0;
    padding-left: 1.25em;
    font-size: var(--fish-font-size);
    line-height: 1.5;
}

li {
    margin-top: 10px;
    margin-bottom: 10px;
}
