blockquote {
    font-size: var(--fish-font-size);
    border-left: 4px solid var(--fish-grey);
    padding: 0.25em 1em;
    margin: 0.5em;
    line-height: 1.5;
    font-style: italic;

    & cite {
        display: block;
        font-style: normal;
        font-size: 0.9em;
    }
}
