details {
    margin: 0.5em 0;
    background-color: var(--fish-white);
    padding: 1em;
    border-radius: var(--fish-radius);

    &[open] {
        & summary {
            margin-bottom: 1em;
        }
    }

    @media (prefers-color-scheme: dark) {
        background-color: var(--fish-dark);
    }
}
