input[type='checkbox'] {
    width: 1em;
    height: 1em;
    padding: 0;
    margin: 0.75em 0;
    border-radius: calc(var(--fish-radius) / 2);
    box-shadow: none;
    border-width: 0;
}

input[type='checkbox'] + label {
    margin: 0;
    margin-left: 0.33em;
}

input[type='checkbox']::before {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
}

input[type='checkbox']:checked::before {
    border-radius: calc(var(--fish-radius) / 2);
    background-color: var(--fish-primary);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    height: 1em;
    width: 1em;
}

input[type='checkbox'] + label + input[type='checkbox'] {
    margin-left: 12px;
}
