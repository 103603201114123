body *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

body::-webkit-scrollbar {
    display: none;
}

*::-webkit-scrollbar-thumb {
    background: var(--fish-grey);
}

body *::-webkit-scrollbar-thumb {
    border-radius: 3px;
}
