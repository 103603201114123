.btn-group > .btn {
  margin-top: .3em;
  margin-right: .3em;
}

hgroup h1 {
  color: var(--fish-font-color);
}

:root {
  --fish-font-size: 17px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.15rem;
}

h5 {
  font-size: 1.05rem;
}

h6 {
  font-size: 1rem;
}

:root {
  --fish-base-font: -apple-system, BlinkMacSystemFont, "Avenir Next", Avenir, "Nimbus Sans L", Roboto, "Noto Sans", "Segoe UI", Arial, Helvetica, "Helvetica Neue", sans-serif;
  --fish-code-font: Fira Code, consolas, monospace;
  box-sizing: border-box;
  font-size: var(--fish-font-size);
  font-family: var(--fish-base-font);
  --fish-font-color: black;
  color: var(--fish-font-color);
  color-scheme: dark light;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --fish-font-color: #bbb;
    background-color: #1b1b1b;
  }
}

html {
  text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

:root {
  --fish-radius: 4px;
  --fish-blue-light: #3498db1a;
  --fish-blue: #3498db;
  --fish-blue-deep: #2980b9;
  --fish-cyan-light: #1abc9c1a;
  --fish-cyan: #1abc9c;
  --fish-cyan-deep: #16a085;
  --fish-green-light: #2ecc711a;
  --fish-green: #2ecc71;
  --fish-green-deep: #27ae60;
  --fish-dark: #303133;
  --fish-dark-deep: #1d1d1d;
  --fish-red-light: #e74c3c1a;
  --fish-red: #e74c3c;
  --fish-red-deep: #c0392b;
  --fish-yellow-light: #f1c40f1a;
  --fish-yellow: #f1c40f;
  --fish-yellow-deep: #e67e22;
  --fish-light: #f2f2f2;
  --fish-light-deep: #1d1e1f;
  --fish-grey: #95a5a6;
  --fish-grey-deep: #39393a;
  --fish-mark: #fff2ca;
  --fish-mark-text: #543a26;
  --fish-white: #f5f6fa;
  --fish-black: #050505;
  --fish-code-color: #f47466;
  --fish-primary-light: var(--fish-blue-light);
  --fish-primary: var(--fish-blue);
  --fish-primary-deep: var(--fish-blue-deep);
  --fish-input-color: #0000004d;
  --fish-secondary-text: #909399;
}

@media (prefers-color-scheme: dark) {
  :root {
    --fish-primary: var(--fish-primary-deep);
    --fish-cyan: var(--fish-cyan-deep);
    --fish-green: var(--fish-green-deep);
    --fish-yellow: var(--fish-yellow-deep);
    --fish-red: var(--fish-red-deep);
    --fish-grey: var(--fish-grey-deep);
    --fish-light: var(--fish-light-deep);
    --fish-secondary-text: #a3a6ad;
  }
}

.blue {
  --fish-primary-light: var(--fish-blue-light);
  --fish-primary: var(--fish-blue);
  --fish-primary-deep: var(--fish-blue-deep);
}

.cyan {
  --fish-primary-light: var(--fish-cyan-light);
  --fish-primary: var(--fish-cyan);
  --fish-primary-deep: var(--fish-cyan-deep);
}

.green, * .success {
  --fish-primary-light: var(--fish-green-light);
  --fish-primary: var(--fish-green);
  --fish-primary-deep: var(--fish-green-deep);
}

.red, * .error {
  --fish-primary-light: var(--fish-red-light);
  --fish-primary: var(--fish-red);
  --fish-primary-deep: var(--fish-red-deep);
}

.yellow, * .warn {
  --fish-primary-light: var(--fish-yellow-light);
  --fish-primary: var(--fish-yellow);
  --fish-primary-deep: var(--fish-yellow-deep);
}

.btn, form button, button.btn, input[type="file"]::-webkit-file-upload-button {
  border-radius: var(--fish-radius);
  font-size: var(--fish-font-size);
  color: #000;
  background-color: #f2f2f2;
  border: none;
  padding: 10px 16px;
  transition: background-color .5s;
}

.btn:hover, form button:hover, button.btn:hover, input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #ddd;
}

@media (prefers-color-scheme: dark) {
  .btn, form button, button.btn, input[type="file"]::-webkit-file-upload-button {
    background-color: var(--fish-dark);
    color: var(--fish-white);
  }

  .btn:hover, form button:hover, button.btn:hover, input[type="file"]::-webkit-file-upload-button:hover {
    background-color: var(--fish-grey);
  }
}

blockquote {
  font-size: var(--fish-font-size);
  border-left: 4px solid var(--fish-grey);
  margin: .5em;
  padding: .25em 1em;
  font-style: italic;
  line-height: 1.5;
}

blockquote cite {
  font-size: .9em;
  font-style: normal;
  display: block;
}

code, pre {
  border-radius: var(--fish-radius);
  font-family: var(--fish-code-font);
}

code {
  color: var(--fish-code-color);
  background-color: #f7f2f4;
  padding: .125em .5em;
}

pre code {
  width: 100%;
  color: #24292e;
  background-color: #f6f8fa;
  margin: 0;
  padding: .75em;
  font-size: .9em;
  display: block;
  overflow: auto;
}

@media (prefers-color-scheme: dark) {
  pre code {
    color: unset;
    background-color: #242529;
  }
}

kbd {
  color: var(--fish-white);
  border-radius: var(--fish-radius);
  font-size: var(--fish-font-size);
  font-family: var(--fish-code-font);
  background-color: #050505;
  padding: .25em .5em;
}

details {
  background-color: var(--fish-white);
  border-radius: var(--fish-radius);
  margin: .5em 0;
  padding: 1em;
}

details[open] summary {
  margin-bottom: 1em;
}

@media (prefers-color-scheme: dark) {
  details {
    background-color: var(--fish-dark);
  }
}

body ::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: var(--fish-grey);
}

body ::-webkit-scrollbar-thumb {
  border-radius: 3px;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

hgroup h1, hgroup h2, hgroup h3, hgroup h4, hgroup h5, hgroup h6 {
  margin-bottom: 0;
}

hgroup > :last-child {
  color: var(--fish-secondary-text);
  margin-top: 4px;
}

del {
  color: var(--fish-red);
}

ins {
  color: var(--fish-green);
  text-decoration: none;
}

table {
  border-spacing: 0;
  border: 1.5px solid #dfe2e5;
  overflow: auto;
}

td, th {
  padding: 0;
}

table th {
  text-align: left;
  background: #fff;
  font-weight: 600;
}

table td, table th {
  padding: .75em;
}

table tr {
  background-color: #fff;
}

table tr:nth-child(2n+1) {
  background-color: #f6f8fa;
}

@media (prefers-color-scheme: dark) {
  table {
    border: 1.5px solid #282a2b;
  }

  table th, table tr {
    background-color: #1b1b1b;
  }

  table tr:nth-child(2n+1) {
    background-color: var(--fish-dark);
  }
}

h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  letter-spacing: .125px;
  margin: .75em 0;
  line-height: 1.2;
}

p {
  overflow-wrap: break-word;
  letter-spacing: .125px;
  margin: 12px 0;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

mark {
  background-color: var(--fish-mark);
  color: var(--fish-mark-text);
  padding: 2px;
}

a {
  color: var(--fish-primary);
  background-color: inherit;
  font-size: var(--fish-font-size);
  padding: 0;
  text-decoration: none;
}

a.button {
  color: var(--fish-primary);
  background-color: var(--fish-light);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--fish-radius);
  padding: .5em;
  transition: background-color .25s;
  display: block;
}

a.button:hover {
  background-color: var(--fish-light-deep);
}

ul, ol {
  font-size: var(--fish-font-size);
  margin: 1em 0;
  padding-left: 1.25em;
  line-height: 1.5;
}

li {
  margin-top: 10px;
  margin-bottom: 10px;
}

form {
  margin-top: .5em;
  margin-bottom: .5em;
}

fieldset {
  border: none;
  margin-bottom: .75em;
  padding: 0;
}

legend {
  font-size: var(--fish-font-size);
  margin-bottom: .25em;
}

fieldset > * {
  vertical-align: middle;
}

input, select {
  width: 100%;
  height: 2.25em;
  border-style: solid;
  border-color: var(--fish-input-color);
  border-radius: var(--fish-radius);
  font-size: var(--fish-font-size);
  background-color: #0000;
  border-width: 2px;
  outline: none;
  margin: .75em 0;
  padding: .5em;
  transition: box-shadow .125s;
}

input:focus, select:focus {
  border-color: var(--fish-primary);
}

input:focus::placeholder, select:focus::placeholder {
  color: #0000;
}

@media (prefers-color-scheme: dark) {
  input, select {
    background-color: var(--fish-dark);
    color: var(--fish-font-color);
    border-color: #0000;
  }

  input::placeholder, select::placeholder {
    color: var(--fish-font-color);
  }

  input:focus-visible, select:focus-visible {
    border-color: none;
  }
}

label {
  font-size: calc(var(--fish-font-size)  - .5px);
}

label + input {
  margin-top: .5em;
}

select {
  padding: .25em .5em;
}

label + select {
  margin-top: .25em;
}

@media (prefers-color-scheme: dark) {
  select {
    background-color: var(--fish-dark);
    color: var(--fish-font-color);
  }
}

input[type="radio"] {
  width: 1em;
  height: 1em;
  box-shadow: none;
  border: none;
  margin: .75em 0;
}

input[type="radio"] + label {
  margin: 0;
  display: inline;
}

input[type="radio"]:before {
  content: "";
  width: 1em;
  height: 1em;
  border-radius: 50%;
  display: block;
  left: 0;
  right: 0;
}

input[type="radio"]:checked:before {
  border: solid .25em var(--fish-primary);
  background-color: #fff;
}

input[type="checkbox"] {
  width: 1em;
  height: 1em;
  border-radius: calc(var(--fish-radius) / 2);
  box-shadow: none;
  border-width: 0;
  margin: .75em 0;
  padding: 0;
}

input[type="checkbox"] + label {
  margin: 0 0 0 .33em;
}

input[type="checkbox"]:before {
  content: "";
  width: 1em;
  height: 1em;
  display: block;
}

input[type="checkbox"]:checked:before {
  border-radius: calc(var(--fish-radius) / 2);
  background-color: var(--fish-primary);
  height: 1em;
  width: 1em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23FFF' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
}

input[type="checkbox"] + label + input[type="checkbox"] {
  margin-left: 12px;
}

input[type="file"] {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: none;
  border: none;
  padding-left: 0;
  background-color: #0000 !important;
}

input[type="file"]::-webkit-file-upload-button {
  margin-right: 8px;
}

input[type="datetime-local"], input[type="date"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
}

@media (prefers-color-scheme: dark) {
  input[type="datetime-local"], input[type="date"] {
    background-color: var(--fish-dark);
  }

  input[type="datetime-local"]::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert() sepia(0%) saturate(0%) hue-rotate(152deg) brightness(103%) contrast(103%);
    padding-right: 6px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1em 1.25em;
}

.container-sm {
  max-width: 1200px;
  max-width: 800px;
  margin: 0 auto;
  padding: 1em 1.25em;
}

/*# sourceMappingURL=index.34014dc6.css.map */
