h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0.75em 0;
    line-height: 1.2;
    overflow-wrap: break-word;
    letter-spacing: 0.125px;
}

p {
    margin: 12px 0;
    line-height: 1.5;
    overflow-wrap: break-word;
    letter-spacing: 0.125px;
}
