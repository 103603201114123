form {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

fieldset {
    padding: 0;
    border: none;
    margin-bottom: 0.75em;
}

legend {
    font-size: var(--fish-font-size);
    margin-bottom: 0.25em;
}

fieldset > * {
    vertical-align: middle;
}
