input[type='file'] {
    padding-left: 0;
    appearance: none;
    vertical-align: middle;
    border: none;
    height: fit-content;
    background-color: transparent !important;
    box-shadow: none;

    &::-webkit-file-upload-button {
        margin-right: 8px;
    }
}
