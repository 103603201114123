@import url(./typography/size.less);
@import url(./typography/font.less);

:root {
    box-sizing: border-box;
    font-size: var(--fish-font-size);
    font-family: var(--fish-base-font);
    --fish-font-color: black;
    color: var(--fish-font-color);
    color-scheme: dark light;
}

*,
*::before,
*::after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

@media (prefers-color-scheme: dark) {
    :root {
        background-color: #1b1b1b;
        --fish-font-color: #bbbbbb;
    }
}
