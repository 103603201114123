input,
select {
    outline: none;
    width: 100%;
    height: 2.25em;
    margin: 0.75em 0;
    padding: 0.5em;
    border-style: solid;
    border-color: var(--fish-input-color);
    border-width: 2px;
    border-radius: var(--fish-radius);
    font-size: var(--fish-font-size);
    background-color: transparent;
    transition: box-shadow 0.125s;
    &:focus {
        border-color: var(--fish-primary);

        &::placeholder {
            color: transparent;
        }
    }

    @media (prefers-color-scheme: dark) {
        background-color: var(--fish-dark);
        color: var(--fish-font-color);
        border-color: transparent;

        &::placeholder {
            color: var(--fish-font-color);
        }

        &:focus-visible {
            border-color: none;
        }
    }
}
