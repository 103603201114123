select {
    padding: 0.25em 0.5em;
}

label + select {
    margin-top: 0.25em;
}

@media (prefers-color-scheme: dark) {
    select {
        background-color: var(--fish-dark);
        color: var(--fish-font-color);
    }
}
