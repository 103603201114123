.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1em 1.25em;
}

.container-sm {
    .container();
    max-width: 800px;
}
