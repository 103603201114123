input[type='radio'] {
    border: none;
    width: 1em;
    height: 1em;
    margin: 0.75em 0;
    box-shadow: none;
}

input[type='radio'] + label {
    display: inline;
    margin: 0;
}

input[type='radio']::before {
    content: '';
    display: block;
    left: 0;
    right: 0;
    width: 1em;
    height: 1em;
    border-radius: 50%;
}

input[type='radio']:checked::before {
    border: solid 0.25em var(--fish-primary);
    background-color: white;
}
