:root {
    --fish-font-size: 17px;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.35rem;
}

h4 {
    font-size: 1.15rem;
}

h5 {
    font-size: 1.05rem;
}

h6 {
    font-size: 1rem;
}
