table {
    border-spacing: 0;
    overflow: auto;
    border: 1.5px solid #dfe2e5;
}

td,
th {
    padding: 0;
}

table th {
    font-weight: 600;
    background: #fff;
    text-align: left;
}

table td,
table th {
    padding: 0.75em;
}

table tr {
    background-color: #fff;
}

table tr:nth-child(2n + 1) {
    background-color: #f6f8fa;
}

@media (prefers-color-scheme: dark) {
    table {
        border: 1.5px solid #282a2b;
        th,
        tr {
            background-color: #1b1b1b;
        }
    }

    table tr:nth-child(2n + 1) {
        background-color: var(--fish-dark);
    }
}
