hgroup {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    & > :last-child {
        margin-top: 4px;
        color: var(--fish-secondary-text);
    }
}
