code,
pre {
    border-radius: var(--fish-radius);
    font-family: var(--fish-code-font);
}

code {
    padding: 0.125em 0.5em;
    background-color: #f7f2f4;
    color: var(--fish-code-color);
}

pre code {
    display: block;
    margin: 0;
    padding: 0.75em;
    font-size: 0.9em;
    width: 100%;
    overflow: auto;
    background-color: #f6f8fa;
    color: #24292e;
}

@media (prefers-color-scheme: dark) {
    pre code {
        background-color: rgb(36, 37, 41);
        color: unset;
    }
}
