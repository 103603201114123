a {
    color: var(--fish-primary);
    text-decoration: none;
    background-color: inherit;
    padding: 0;
    font-size: var(--fish-font-size);
}

a.button {
    color: var(--fish-primary);
    display: block;
    background-color: var(--fish-light);
    width: fit-content;
    padding: 0.5em;
    border-radius: var(--fish-radius);
    transition: background-color 0.25s;

    &:hover {
        background-color: var(--fish-light-deep);
    }
}
